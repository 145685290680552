import styled from "styled-components";
import React from "react";
interface Props {
  width?: string;
  height?: string;
}

const Svg = styled.svg`
  width: 219px;
  height: 37px;
  @media (min-width: 1200px) {
    width: 197px;
    height: 32px;
  }
`;
export function RapidLogoFooter(props: Props) {
  return (
    <Svg
      width={"100%"}
      height={"100%"}
      viewBox="0 0 256 43"
      background-color="transparent"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6792 30.444C22.851 30.96 23.0228 31.304 23.3664 31.648H18.5557C18.3839 31.476 18.2121 31.132 18.0403 30.788C17.8685 30.272 17.6966 29.756 17.6966 29.068C17.5248 28.38 17.5248 27.348 17.353 25.972C17.1812 24.596 17.0094 23.564 17.0094 22.876C16.8376 21.844 16.6658 21.156 16.3221 20.468C15.9785 19.952 15.4631 19.436 14.9477 19.264C14.2604 18.92 13.4013 18.748 12.1987 18.748H4.63893V31.476H0V1.20401H12.7141C14.7758 1.20401 16.494 1.54801 18.0403 2.23601C19.5866 2.92401 20.6175 3.78401 21.4765 4.98801C22.1638 6.19201 22.6792 7.74001 22.6792 9.46001C22.6792 11.352 22.1638 12.9 21.3047 14.104C20.2738 15.308 18.8993 16.168 17.1812 16.684C18.0403 16.856 18.8993 17.028 19.4148 17.544C19.9302 18.06 20.4456 18.576 20.7893 19.264C21.1329 20.124 21.4765 21.156 21.4765 22.188C21.6483 23.22 21.8201 24.252 21.8201 25.284C21.8201 25.628 21.8201 25.972 21.9919 26.66C21.9919 27.348 22.1638 27.864 22.1638 28.38C22.3356 29.068 22.5074 29.756 22.6792 30.444ZM12.0268 15.136C14.0886 15.136 15.6349 14.792 16.494 13.932C17.5248 13.072 17.8685 11.868 17.8685 10.148C17.8685 6.70801 15.9785 4.98801 12.0268 4.98801H4.63893V15.136H12.0268Z"
        fill="white"
      />
      <path
        d="M41.5785 11.008C43.1249 12.556 43.9839 14.964 43.9839 17.888V31.304H40.8913C40.8913 30.1 40.7195 29.24 40.5477 28.724C40.5477 28.552 40.3759 28.38 40.3759 28.38C40.3759 28.38 40.204 28.38 40.204 28.208H40.0322C39.8604 28.208 39.6886 28.208 39.6886 28.38C39.5168 28.552 39.345 28.724 39.1732 28.896C37.7987 30.96 35.5651 31.992 32.4725 31.992C30.9262 31.992 29.5517 31.648 28.1772 31.132C26.9745 30.616 25.9436 29.756 25.2564 28.724C24.5691 27.692 24.2255 26.316 24.2255 24.94C24.2255 20.812 27.1463 18.576 32.9879 18.232C36.2524 18.06 38.6577 17.716 39.6886 17.372C39.6886 15.652 39.1732 14.276 38.3141 13.416C37.4551 12.556 36.0806 12.04 34.5342 12.04C33.1598 12.04 31.9571 12.384 30.9262 13.072C30.0671 13.76 29.3799 14.792 29.0363 15.824H24.741C25.0846 14.448 25.6 13.072 26.4591 12.04C27.3181 11.008 28.5208 9.97601 29.7235 9.46001C31.4416 8.94401 32.9879 8.60001 34.7061 8.60001C37.7987 8.60001 40.0322 9.46001 41.5785 11.008ZM39.6886 20.812C39.0014 20.812 38.1423 20.984 37.4551 21.156C36.4242 21.328 35.0497 21.5 33.3316 21.672C31.6134 21.844 30.4108 22.188 29.5517 22.704C28.8645 23.22 28.349 24.08 28.349 25.112C28.349 26.316 28.8644 27.348 29.7235 27.864C30.5826 28.552 31.7853 28.896 33.1598 28.896C35.9087 28.896 37.6269 27.864 38.6577 25.628C39.5168 24.252 39.6886 22.704 39.6886 20.812Z"
        fill="white"
      />
      <path
        d="M47.2483 40.936V9.28801H50.3409C50.3409 10.148 50.5127 10.836 50.5127 11.696C50.5127 11.868 50.5127 12.212 50.6845 12.384L50.8563 12.556H51.0282C51.2 12.556 51.3718 12.556 51.3718 12.384C51.5436 12.212 51.7154 12.04 51.8872 11.868C53.7772 9.63201 56.3543 8.42801 59.6188 8.42801C61.6805 8.42801 63.5704 8.94401 65.2886 9.97601C67.0067 11.008 68.3812 12.384 69.2402 14.104C70.2711 15.824 70.6147 17.888 70.6147 20.124C70.6147 22.36 70.0993 24.424 69.0684 26.144C68.2094 27.864 66.6631 29.412 64.9449 30.272C63.2268 31.304 61.1651 31.82 59.1033 31.82C57.7288 31.82 56.3543 31.648 54.9798 30.96C54.2926 30.96 53.2617 30.1 52.2308 29.24C52.2308 29.24 52.059 29.068 51.8872 29.068H51.7154C51.3718 29.068 51.3718 29.24 51.3718 29.756V41.108L47.2483 40.936ZM62.7114 27.52C63.9141 26.832 64.7731 25.8 65.4604 24.596C66.1476 23.22 66.4912 21.844 66.4912 20.468C66.4912 19.092 66.1476 17.544 65.4604 16.34C64.7731 15.136 63.9141 14.104 62.7114 13.416C61.5087 12.728 60.1342 12.384 58.7597 12.384C57.7288 12.384 56.698 12.556 55.8389 12.9C54.9798 13.244 54.1208 13.76 53.4335 14.448C52.7463 15.136 52.2308 15.996 51.7154 17.028C51.3718 18.06 51.2 19.264 51.2 20.296C51.2 21.5 51.3718 22.532 51.7154 23.564C52.059 24.596 52.5745 25.456 53.4335 26.144C54.1208 26.832 54.9798 27.348 55.8389 27.692C56.8698 28.036 57.7288 28.208 58.7597 28.208C60.306 28.552 61.6805 28.208 62.7114 27.52Z"
        fill="white"
      />
      <path
        d="M72.8483 5.15999C72.5047 4.98799 72.3329 4.64399 72.1611 4.29999C72.1611 3.95599 72.1611 3.61199 72.1611 3.26799C72.1611 2.92399 72.1611 2.57999 72.3329 2.23599C72.5047 1.89199 72.6765 1.54799 73.0201 1.37599C73.5356 0.859991 74.2228 0.515991 75.0819 0.515991C75.7691 0.515991 76.6282 0.687991 77.1436 1.37599C77.3154 1.54799 77.4872 1.71999 77.6591 2.06399C77.8309 2.40799 77.8309 2.75199 77.8309 3.09599C77.8309 3.43999 77.8309 3.78399 77.6591 4.12799C77.4872 4.64399 77.3154 4.81599 76.9718 5.15999C76.4564 5.67599 75.7691 6.01999 74.9101 5.84799C74.2228 5.84799 73.5356 5.67599 72.8483 5.15999ZM77.1436 31.476H72.8483V9.28799H77.1436V31.476Z"
        fill="white"
      />
      <path
        d="M84.5316 30.616C82.8134 29.584 81.4389 28.208 80.5799 26.488C79.549 24.768 79.2054 22.704 79.2054 20.468C79.2054 18.404 79.7208 16.34 80.5799 14.448C81.4389 12.728 82.9853 11.18 84.5316 10.148C86.2497 9.116 88.3114 8.6 90.3732 8.6C91.7477 8.6 93.1222 8.944 94.4967 9.46C95.6993 9.976 96.7302 10.664 97.5893 11.696C97.5893 11.696 97.7611 11.868 97.9329 11.868H98.1047C98.4483 11.868 98.4483 11.696 98.4483 11.18V0H102.744V31.476H99.4792C99.4792 30.616 99.3074 29.928 99.3074 29.068C99.3074 28.896 99.3074 28.552 99.1356 28.38L98.9638 28.208H98.792C98.6202 28.208 98.4483 28.208 98.4483 28.38C98.2765 28.552 98.1047 28.724 97.9329 28.896C96.043 31.132 93.4658 32.336 90.2014 32.336C88.1396 32.164 86.2497 31.648 84.5316 30.616ZM93.9812 28.036C94.8403 27.692 95.6993 27.176 96.3866 26.488C97.0738 25.8 97.7611 24.94 98.1047 23.908C98.4483 22.876 98.6202 21.672 98.6202 20.64C98.6202 19.436 98.4483 18.404 98.1047 17.372C97.7611 16.34 97.2457 15.48 96.3866 14.792C95.6993 14.104 94.8403 13.588 93.9812 13.244C92.9504 12.9 92.0913 12.728 91.0604 12.728C89.6859 12.728 88.3114 13.072 87.1087 13.76C85.9061 14.448 85.047 15.48 84.3597 16.684C83.6725 18.06 83.3289 19.436 83.3289 20.812C83.3289 22.188 83.6725 23.736 84.3597 24.94C85.047 26.144 85.9061 27.176 87.1087 27.864C88.3114 28.552 89.6859 28.896 91.0604 28.896C92.0913 28.552 93.1222 28.38 93.9812 28.036Z"
        fill="white"
      />
      <path d="M120.784 31.476H116.145V1.20401H120.784V31.476Z" fill="white" />
      <path
        d="M124.736 31.476V9.28801H127.828C127.828 9.46001 127.828 9.63201 127.828 9.80401C128 11.008 128 11.524 128 11.696C128 12.04 128.172 12.212 128.172 12.384L128.344 12.556H128.515C128.687 12.556 128.687 12.556 128.859 12.384C129.031 12.212 129.203 12.04 129.203 11.868C130.062 10.664 131.093 9.80401 132.124 9.11601C133.67 8.94401 134.873 8.60001 136.419 8.60001C137.965 8.60001 139.34 8.94401 140.37 9.46001C141.401 10.148 142.26 11.008 142.948 12.384C143.119 12.556 143.119 12.9 143.291 13.072L143.463 13.244H143.635C143.807 13.244 143.979 13.244 144.15 13.072C144.322 12.9 144.494 12.728 144.666 12.556C145.525 11.18 146.556 10.148 147.758 9.63201C148.789 8.94401 150.164 8.60001 151.538 8.60001C153.085 8.60001 154.631 8.94401 155.662 9.63201C156.864 10.32 157.724 11.524 158.411 12.728C159.098 14.104 159.27 15.652 159.27 17.544V31.304H154.975V18.232C154.975 16.34 154.631 14.964 153.772 13.76C152.913 12.728 151.882 12.212 150.336 12.212C149.477 12.212 148.446 12.384 147.758 12.9C146.899 13.416 146.212 14.104 145.697 14.964C144.666 16.684 143.979 18.92 143.979 22.016V31.304H139.683V18.232C139.683 16.34 139.34 14.792 138.481 13.76C137.622 12.728 136.591 12.212 135.044 12.212C134.185 12.212 133.154 12.384 132.467 12.9C131.608 13.416 130.921 14.104 130.405 14.964C129.718 15.996 129.375 17.028 129.203 18.06C128.859 19.436 128.859 20.64 128.859 22.016V31.304H124.736V31.476Z"
        fill="white"
      />
      <path
        d="M178.685 11.008C180.231 12.556 181.09 14.964 181.09 17.888V31.304H177.997C177.997 30.1 177.826 29.24 177.654 28.724C177.654 28.552 177.482 28.38 177.482 28.38C177.482 28.38 177.31 28.38 177.31 28.208H177.138C176.966 28.208 176.795 28.208 176.795 28.38C176.623 28.552 176.451 28.724 176.279 28.896C174.905 30.96 172.671 31.992 169.579 31.992C168.032 31.992 166.658 31.648 165.283 31.132C164.081 30.616 163.05 29.756 162.362 28.724C161.675 27.692 161.332 26.316 161.332 24.94C161.332 20.812 164.252 18.576 170.094 18.232C173.358 18.06 175.764 17.716 176.795 17.372C176.795 15.652 176.279 14.276 175.42 13.416C174.561 12.556 173.187 12.04 171.64 12.04C170.266 12.04 169.063 12.384 168.032 13.072C167.173 13.76 166.486 14.792 166.142 15.824H161.847C162.191 14.448 162.706 13.072 163.565 12.04C164.424 11.008 165.627 9.97601 166.83 9.46001C168.548 8.94401 170.094 8.60001 171.812 8.60001C174.733 8.60001 177.138 9.46001 178.685 11.008ZM176.795 20.812C176.107 20.812 175.248 20.984 174.561 21.156C173.53 21.328 172.156 21.5 170.438 21.672C168.719 21.844 167.517 22.188 166.658 22.704C165.97 23.22 165.455 24.08 165.455 25.112C165.455 26.316 165.97 27.348 166.83 27.864C167.689 28.552 168.891 28.896 170.266 28.896C173.015 28.896 174.733 27.864 175.764 25.628C176.623 24.252 176.795 22.704 176.795 20.812Z"
        fill="white"
      />
      <path
        d="M189.509 40.248C187.962 39.56 186.76 38.528 185.557 37.324C184.526 36.12 183.839 34.572 183.495 33.024H187.791C188.134 34.4 188.993 35.604 190.196 36.464C191.399 37.324 192.945 37.668 194.663 37.668C196.897 37.668 198.443 37.152 199.646 35.948C200.848 34.916 201.364 33.196 201.364 31.132V28.552C201.364 28.208 201.192 28.036 201.02 28.036C200.848 28.036 200.676 28.208 200.505 28.38C199.646 29.24 198.615 29.928 197.412 30.444C196.209 30.96 194.835 31.132 193.46 31.132C191.57 31.132 189.509 30.616 187.791 29.756C186.072 28.896 184.87 27.52 183.839 25.8C182.808 24.08 182.464 22.016 182.464 20.124C182.464 17.888 182.98 15.996 183.839 14.276C184.698 12.556 186.072 11.18 187.791 10.32C189.509 9.288 191.227 8.944 193.117 8.944C194.663 8.944 196.038 9.288 197.24 9.804C198.443 10.32 199.646 11.18 200.505 12.212C200.676 12.384 200.848 12.556 201.02 12.728C201.192 12.728 201.192 12.9 201.364 12.9H201.536C201.536 12.9 201.707 12.9 201.707 12.728C201.879 12.556 201.879 12.384 201.879 12.04C201.879 11.696 202.051 11.352 202.051 10.836C202.051 10.492 202.051 9.976 202.223 9.804H205.315V31.476C205.315 33.54 204.8 35.432 203.941 36.98C203.082 38.528 201.707 39.732 200.161 40.42C198.443 41.28 196.553 41.624 194.319 41.624C192.945 41.28 191.055 40.936 189.509 40.248ZM197.412 27.004C198.271 26.66 198.958 26.144 199.646 25.628C200.333 24.94 200.848 24.08 201.192 23.22C201.536 22.188 201.707 21.156 201.707 20.124C201.707 19.092 201.536 18.06 201.192 17.028C200.848 16.168 200.333 15.308 199.646 14.62C198.958 13.932 198.271 13.416 197.412 13.244C196.553 12.9 195.522 12.728 194.663 12.728C193.289 12.728 192.086 13.072 190.883 13.76C189.852 14.448 188.821 15.308 188.306 16.512C187.619 17.716 187.275 19.092 187.447 20.468C187.447 21.844 187.791 23.22 188.306 24.424C188.821 25.456 189.852 26.488 190.883 27.004C192.086 27.692 193.289 28.036 194.663 28.036C195.522 27.52 196.381 27.348 197.412 27.004Z"
        fill="white"
      />
      <path
        d="M230.228 21.844H212.188C212.188 23.048 212.703 24.252 213.219 25.284C213.906 26.316 214.765 27.004 215.796 27.692C216.827 28.208 218.201 28.552 219.404 28.552C220.95 28.552 222.325 28.208 223.527 27.52C224.73 26.832 225.417 25.8 225.761 24.768H230.056C229.713 26.316 229.025 27.52 227.995 28.724C226.964 29.928 225.761 30.788 224.387 31.304C222.84 31.992 221.122 32.164 219.404 32.164C217.17 32.164 215.109 31.648 213.391 30.616C211.672 29.584 210.298 28.208 209.267 26.488C208.236 24.768 207.893 22.704 207.893 20.296C207.893 17.888 208.408 15.996 209.439 14.104C210.47 12.384 211.844 11.008 213.562 9.97601C215.281 8.94401 217.342 8.42801 219.404 8.42801C221.638 8.42801 223.527 8.94401 225.246 9.80401C226.964 10.664 228.166 12.04 229.197 13.76C230.056 15.48 230.572 17.372 230.572 19.608C230.4 20.468 230.4 21.156 230.228 21.844ZM215.968 12.9C214.937 13.416 214.078 14.276 213.562 15.136C212.875 16.168 212.532 17.372 212.36 18.404H226.276C226.276 17.2 225.933 16.168 225.246 15.136C224.73 14.104 223.871 13.416 222.84 12.9C221.809 12.384 220.607 12.04 219.404 12.04C218.201 12.212 216.999 12.384 215.968 12.9Z"
        fill="white"
      />
      <path
        d="M233.836 30.272C232.118 29.068 231.087 27.176 230.744 24.94H235.039C235.211 25.456 235.383 25.972 235.554 26.488C235.898 27.004 236.242 27.348 236.757 27.692C237.616 28.38 238.991 28.724 240.537 28.724C241.911 28.724 242.942 28.38 243.801 27.864C244.66 27.348 245.004 26.488 245.004 25.628C245.004 25.112 244.832 24.424 244.489 23.908C244.145 23.392 243.63 23.048 242.942 22.876C242.255 22.532 241.052 22.36 239.678 22.016C237.616 21.672 236.07 21.156 234.867 20.64C233.664 20.124 232.805 19.264 232.29 18.404C231.775 17.544 231.431 16.34 231.431 15.136C231.431 13.932 231.775 12.728 232.462 11.696C233.149 10.664 234.18 9.80401 235.383 9.46001C236.585 8.94401 238.132 8.60001 239.678 8.60001C242.255 8.60001 244.317 9.11601 245.691 10.32C247.066 11.524 248.097 13.244 248.268 15.48H244.145C243.973 14.276 243.458 13.416 242.599 12.728C241.911 12.212 240.881 11.868 239.506 11.868C238.132 11.868 237.101 12.04 236.413 12.556C236.07 12.728 235.726 13.072 235.554 13.416C235.383 13.76 235.211 14.276 235.211 14.62C235.211 15.136 235.383 15.824 235.726 16.168C236.07 16.512 236.585 16.856 237.273 17.2C238.132 17.544 239.162 17.716 240.021 17.888C242.255 18.232 243.973 18.748 245.176 19.264C246.379 19.78 247.238 20.64 247.925 21.5C248.44 22.36 248.784 23.564 248.784 24.94C248.784 26.144 248.44 27.348 247.753 28.38C247.066 29.412 246.035 30.272 244.66 30.788C243.286 31.304 241.74 31.648 240.193 31.648C237.788 32.164 235.554 31.648 233.836 30.272Z"
        fill="white"
      />
    </Svg>
  );
}
