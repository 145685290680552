import React from "react";
import styled from "styled-components";
import { RapidLogoFooter } from "./RapidLogoFooter";

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  @media (min-width: 750px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
`;

const DigitalProduct = styled.p`
  width: 60%;
  text-align: left;
  font-size: 20px;
  font-weight: 200;
  font-family: "Manrope", sans-serif;
  @media (min-width: 750px) {
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

const PolicyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 1rem;
  @media (min-width: 750px) {
    flex-direction: column;
    padding-bottom: 0;
  }
`;

const PolicyText = styled.p`
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 600;
  @media (min-width: 750px) {
    margin: 0;
  }
`;

export function Footer() {
  return (
    <>
      <FooterContainer>
        <RapidLogoFooter />
        <DigitalProduct>A digital product experience company</DigitalProduct>
        <PolicyContainer>
          <PolicyText>Privacy Policy</PolicyText>
          <PolicyText>Environmental Policy</PolicyText>
        </PolicyContainer>
      </FooterContainer>
    </>
  );
}
