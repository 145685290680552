import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import TermsAndConditionModal from "./TermsConditionsModal";
import FutureEventsModal from "./FutureEventsModal";
import EmailReceivedModal from "./EmailReceivedModal";

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: black;
`;

const Input = styled.input`
  padding: 8px 8px 10px 0px;
  border-bottom: 1px solid white;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: black;
  text-align: left;
  font-family: "Manrope", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: white;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: white;
  }
  :-ms-input-placeholder {
    color: white;
  }
  &:focus {
    outline: none;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: black;
  color: #fff;
  border: 1px solid white;
  border-radius: 100px;
  cursor: pointer;
  width: 140px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const WelcomeTitle = styled.h1`
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 28px;
  margin: 0;
  text-align: left;
`;

const ErrorText = styled.p`
  margin-top: 24rem;
  position: absolute;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  font-family: "Manrope", sans-serif;
  color: #d50000;
  max-width: 40%;
  @media (min-width: 750px) {
    max-width: 50%;
  }
`;

const SuccessText = styled.p`
  margin-top: 24rem;
  position: absolute;
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  font-family: "Manrope", sans-serif;
  color: white;
  max-width: 40%;
  @media (min-width: 750px) {
    max-width: 50%;
  }
`;

const TermsConditionsContainer = styled.div`
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [food, setFood] = useState("");
  const [status, setStatus] = useState(0);
  const [inviteMe, setInviteMe] = useState(false);
  const [inputError, setInputError] = useState("");
  const [approved, setApproved] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  async function submitData() {
    let validInput = validateInput();
    if (!validInput) return;
    try {
      const response = await axios.post(
        "https://vuhvopgcfc.execute-api.eu-north-1.amazonaws.com/default/writeParticipant",
        {
          email: name,
          name: email,
          company: company || "",
          foodPreferences: food || "",
          inviteMe: inviteMe ? "true" : "false",
        }
      );
      setStatus(response.status);
      setOpenModal(true); // Open modal when form is submitted successfully
      setName("");
      setEmail("");
      setCompany("");
      setFood("");
      setInviteMe(false);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  function validateInput() {
    if (name.length === 0) {
      setInputError("Name can't be empty");
      return false;
    }
    if (email.length < 5) {
      setInputError("Email should be at least 5 characters long");
      return false;
    }
    if (email.split("").filter((x) => x === "@").length !== 1) {
      setInputError("Email should contain a @");
      return false;
    }
    if (email.indexOf(".") === -1) {
      setInputError("Email should contain at least one dot");
      return false;
    }
    if (!approved) {
      setInputError(
        "You have to accept our Privacy Policy in order to sign up"
      );
      return false;
    }

    setInputError("");
    return true;
  }

  return (
    <FormWrapper>
      <WelcomeTitle>Sign up here</WelcomeTitle>
      <Input
        type="text"
        placeholder="Name*"
        value={name}
        onChange={(event) => setName(event.target.value)}
        required
      />
      <Input
        type="email"
        placeholder="Email*"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        required
      />
      <Input
        type="text"
        placeholder="Company"
        value={company}
        onChange={(event) => setCompany(event.target.value)}
      />
      <Input
        type="text"
        placeholder="Food Preferences / Allergies"
        value={food}
        onChange={(event) => setFood(event.target.value)}
      />
      {inputError ? (
        <ErrorText>{inputError}</ErrorText>
      ) : (
        <SuccessText>{status === 200 && "Sign up sent!"}</SuccessText>
      )}
      <TermsConditionsContainer>
        <TermsAndConditionModal setApproved={setApproved} />
        <FutureEventsModal setInviteMe={setInviteMe} />
        {status === 200 && (
          <EmailReceivedModal
            open={openModal}
            onClose={() => setOpenModal(!openModal)}
          />
        )}
      </TermsConditionsContainer>
      <ButtonContainer>
        <Button
          onClick={(e) => {
            e.preventDefault();
            submitData();
            setOpenModal(openModal);
          }}
          type="submit"
        >
          Register
          <svg
            width="25"
            height="21"
            viewBox="0 0 25 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 10.1852H23.6111M23.6111 10.1852L14.8148 1.38889M23.6111 10.1852L14.8148 18.9815"
              stroke="white"
              strokeWidth="1.38889"
            />
          </svg>
        </Button>
      </ButtonContainer>
    </FormWrapper>
  );
};
export default Form;
