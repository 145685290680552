import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import styled from "styled-components";

export interface Props {
  open: boolean;
  onClose: () => void;
}

const EventsModal = styled.div`
  max-height: 15rem;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "whitesmoke",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function EmailReceivedModal(props: Props) {
  const handleClose = () => {
    props.onClose();
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 250 }}>
          <EventsModal>
            <h2
              style={{
                color: "black",
                fontFamily: "Manrope",
                fontWeight: "600",
              }}
              id="parent-modal-title"
            >
              We have received your sign up!
              <br />
            </h2>
            <p
              style={{
                color: "black",
                fontFamily: "Manrope",
                fontWeight: "500",
                lineHeight: "1.5rem",
              }}
              id="parent-modal-description"
            >
              No, we don't send a confirmation mail!
            </p>
          </EventsModal>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2rem",
            }}
          >
            <Button onClick={handleClose} color={"error"}>
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
