import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Checkbox } from "@mui/material";
import { green } from "@mui/material/colors";
import styled from "styled-components";

export interface Props {
  setInviteMe: (checked: boolean) => void;
}

const EventsModal = styled.div`
  max-height: 15rem;
  overflow: scroll;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "black",
  border: "2px solid #FFFFFF",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function FutureEventsModal(props: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <Button
          style={{
            color: "white",
            padding: "0.5rem 1rem",
            fontSize: "12px",
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleOpen}
        >
          Invite me to future events
        </Button>
        <Checkbox
          {...label}
          sx={{
            color: green[800],
            "&.Mui-checked": {
              color: green[600],
            },
          }}
          onChange={(e) => props.setInviteMe(e.target.checked)}
        />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 250 }}>
          <EventsModal>
            <h2
              style={{
                color: "white",
                fontFamily: "Manrope",
                fontWeight: "600",
              }}
              id="parent-modal-title"
            >
              Future Events
            </h2>
            <p
              style={{
                color: "white",
                fontFamily: "Manrope",
                fontWeight: "500",
                lineHeight: "1.5rem",
              }}
              id="parent-modal-description"
            >
              We are going to use your email exclusively to send you invites to
              our upcoming 3D events. <br />
              We do not run more than two events per year and you will not
              receive more than two mails per event. Sounds fair, right? So,
              check the box and don't miss an event!"
            </p>
          </EventsModal>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2rem",
            }}
          >
            <Button onClick={handleClose} color={"error"}>
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
