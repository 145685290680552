import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { Checkbox } from "@mui/material";
import { green } from "@mui/material/colors";
import styled from "styled-components";

export interface Props {
  setApproved: (checked: boolean) => void;
}

const TermsModal = styled.div`
  max-height: 15rem;
  overflow: scroll;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "black",
  border: "2px solid #FFFFFF",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function TermsAndConditionModal(props: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <div>
      <div style={{ display: "flex" }}>
        <Button
          style={{
            color: "white",
            padding: "0.5rem 1rem",
            fontSize: "12px",
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
          }}
          onClick={handleOpen}
        >
          Accept Privacy Policy
        </Button>
        <Checkbox
          {...label}
          sx={{
            color: green[800],
            "&.Mui-checked": {
              color: green[600],
            },
          }}
          onChange={(e) => props.setApproved(e.target.checked)}
        />
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 250 }}>
          <TermsModal>
            <h2
              style={{
                color: "white",
                fontFamily: "Manrope",
                fontWeight: "600",
              }}
              id="parent-modal-title"
            >
              Privacy Policy
            </h2>
            <p
              style={{
                color: "white",
                fontFamily: "Manrope",
                fontWeight: "500",
                lineHeight: "1.5rem",
              }}
              id="parent-modal-description"
            >
              We are committed to protecting your privacy and complying with the
              European Union's General Data Protection Regulation (GDPR)
              requirements. When you sign up for our event, we will collect
              certain personal information from you, such as your name and email
              address.
              <br />
              <br />
              This information will be used solely for the purpose of
              communicating with you about the event and providing you with
              relevant updates and information related to the event. We will not
              share your information with any third parties for marketing
              purposes without your explicit consent.
              <br />
              <br />
              By signing up for our event, you agree to our collection, storage,
              and processing of your personal information in accordance with
              this policy. If you do not agree to this policy, please do not
              sign up for our event. If you have any questions or concerns about
              our use of your personal information, please do not hesitate to
              contact us. We are committed to ensuring the privacy and security
              of your personal information, and we will do everything in our
              power to address any issues or concerns that you may have.
            </p>
          </TermsModal>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "2rem",
            }}
          >
            <Button onClick={handleClose} color={"error"}>
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
