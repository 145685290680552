//@ts-nocheck
import React from "react";
import styled from "styled-components";
import { PeopleData } from "../Data/PeopleData";

const PeopleContainer = styled.div`
  @media (min-width: 950px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`;
const PersonInfo = styled.div`
  @media (min-width: 950px) {
    width: 45%;
    box-sizing: border-box;
  }
`;

const PersonPhoto = styled.img`
  margin-top: 1.5rem;
  object-fit: cover;
  width: 100%;
  height: 400px;
`;
const PersonName = styled.h1`
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  font-family: "Manrope", sans-serif;
`;
const PersonDescription = styled.p`
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
`;
const Link = styled.a`
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const SocialLinkList = styled.li``;
const SocialLinks = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  list-style: none;
  font-family: "Manrope", sans-serif;
  text-transform: capitalize;
  justify-content: flex-start;
  padding: 0;
`;
export function RapidPeople() {
  return (
    <>
      <PeopleContainer>
        {PeopleData.map((person, i) => {
          return (
            <PersonInfo key={i}>
              <PersonPhoto src={person.image} alt="Rapid Employee" />
              <PersonName>{person.name}</PersonName>
              <PersonDescription>{person.description}</PersonDescription>
              <SocialLinks>
                {person.weblink.map((p, i: number) => {
                  return (
                    <SocialLinkList>
                      <Link key={i} href={p.link} target="_blank">
                        {p.linkName}

                        <svg
                          width="14"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.41683 4.58337H3.50016C3.19074 4.58337 2.894 4.70629 2.6752 4.92508C2.45641 5.14388 2.3335 5.44062 2.3335 5.75004V11C2.3335 11.3095 2.45641 11.6062 2.6752 11.825C2.894 12.0438 3.19074 12.1667 3.50016 12.1667H8.75016C9.05958 12.1667 9.35633 12.0438 9.57512 11.825C9.79391 11.6062 9.91683 11.3095 9.91683 11V8.08337"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.8335 8.66671L11.6668 2.83337"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M8.75 2.83337H11.6667V5.75004"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Link>
                    </SocialLinkList>
                  );
                })}
              </SocialLinks>
            </PersonInfo>
          );
        })}
      </PeopleContainer>
    </>
  );
}
