import React from "react";
import "./App.css";
import styled from "styled-components";
import { RapidLogo } from "./Components/RapidLogo";
import RapidCube from "./Components/RapidCube";
import Form from "./Components/Form";
import { RapidPeople } from "./Components/RapidPeople";
import { InformationCubes } from "./Components/InformationCubes";
import { PreviousEvents } from "./Components/PreviousEvents";
import { Footer } from "./Components/Footer";
import { Agenda } from "./Components/Agenda";

interface Props {
  color?: string;
  width?: number;
  height?: number;
}

const App = styled.div`
  user-select: none;
  text-align: center;
  background-color: black;
  min-height: 150vh;
  @media (min-width: 1200px) {
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
  }
`;
const RapidHeaderContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1440px;
`;

const TitleCubeFormContainer = styled.div<Props>`
  display: flex;
  flex-direction: column;
  @media (min-width: 1200px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 0rem 10rem;
    margin-top: 10rem;
  }
`;

const RapidLogoContainer = styled.div<Props>`
  left: 50%;
  transform: translate(-50%);
  margin-top: 2.5rem;
  z-index: 1;
  position: absolute;
`;

const RapidCubeContainer = styled.div<Props>`
  display: flex;
  justify-content: center;
  overflow: hidden;
  z-index: 0;
  @media (min-width: 750px) {
    overflow: visible;
  }
`;

const RapidSignUpFormContainer = styled.div`
  height: 100%;
  @media (min-width: 1200px) {
    height: 50%;
    margin-top: 8rem;
    width: 50%;
  }
`;
const RapidSignUpForm = styled.div<Props>`
  z-index: 0;
  position: relative;
  margin-top: -5rem;
  padding: 0px 23px 50px 23px;
  @media (min-width: 750px) {
    padding: 0 10rem;
  }
  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const RapidPeopleContainer = styled.div<Props>`
  margin-top: 5rem;
  padding: 0px 23px;
  @media (min-width: 700px) {
    padding: 0rem 10rem;
  }
`;

const RapidPeopleTitle = styled.h1<Props>`
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 28px;
  margin: 0;
  text-align: left;
`;

const InformationCubesContainer = styled.div`
  margin-top: 6rem;
  padding: 0px 23px;
  @media (min-width: 750px) {
    padding: 0rem 10rem;
  }
`;

const PreviousEventsContainer = styled.div`
  margin-top: 8rem;
  padding: 0px 23px;
  @media (min-width: 750px) {
    padding: 0rem 10rem;
  }
`;

const FooterContainer = styled.div`
  margin-top: 8rem;
  padding: 0px 23px;
  @media (min-width: 750px) {
    padding: 0rem 10rem;
  }
`;

const AgendaHolder = styled.div`
  padding: 0px 23px;
  margin-top: 3rem;
  margin-bottom: 3rem;
  @media (min-width: 1200px) {
    padding: 0rem 10rem;
    margin-top: 5rem;
  }
`;
function LandingPage() {
  return (
    <App>
      <header className="App-header">
        <RapidHeaderContainer>
          <RapidLogoContainer>
            <RapidLogo />
          </RapidLogoContainer>
          <TitleCubeFormContainer>
            <RapidCubeContainer>
              <RapidCube />
            </RapidCubeContainer>
            <RapidSignUpFormContainer>
              <RapidSignUpForm>
                <Form />
              </RapidSignUpForm>
            </RapidSignUpFormContainer>
          </TitleCubeFormContainer>
          <AgendaHolder>
            <Agenda />
          </AgendaHolder>
          <RapidPeopleContainer>
            <RapidPeopleTitle> Who will be presenting?</RapidPeopleTitle>
            <RapidPeople />
          </RapidPeopleContainer>
          <InformationCubesContainer>
            <InformationCubes />
          </InformationCubesContainer>
          <PreviousEventsContainer>
            <PreviousEvents />
          </PreviousEventsContainer>
          <FooterContainer>
            <Footer />
          </FooterContainer>
        </RapidHeaderContainer>
      </header>
    </App>
  );
}

export default LandingPage;
