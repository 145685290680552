import KajsaElin from "../Assets/KajsaElin.png";
import Oliver from "../Assets/Oliver.png";
import Alex from "../Assets/Alex_cropped_v2.jpg";
import Johan from "../Assets/JR_Front[40].jpg"

export const PeopleData = [
  {
    name: "Kajsa Blomberg & Elin Adermalm",
    description: `Elin Adermalm, Studio Manager, and Kajsa Blomberg, Creative Director from Brickland—a CGI studio with a history in product animation—invite you to join them as they delve into the art of finding beauty in the ordinary. Together, they will discuss their creative approach to crafting engaging and compelling product animation stories.
      `,
    weblink: [
      {
        linkName: "linkedin Kajsa",
        link: "https://www.linkedin.com/in/kajsa-blomberg-0a19339b/",
      },{
        linkName: "linkedin Elin",
        link: "https://www.linkedin.com/in/elin-adermalm-03855b112/",
      },
    ],
    image: [KajsaElin],
  },
  {
    name: "Alexander Young",
    description:
      `Alexander has worked in the IT industry for almost 30 years at various companies such as Ericsson, Sun Microsystems, Oracle and Microsoft.
      He is currently a Technical Marketing Engineer with NVIDIA’s Metropolis product group, which builds SW frameworks, AI models & tools to develop computer vision applications. 
      `,
    
      weblink: [
      {
        linkName: "linkedin",
        link: "https://www.linkedin.com/in/alexanderyoung/",
      },
    ],
    
    image: [Alex],
  },
  {
    name: "Dr. Oliver Edsberger",
    description: `Oliver is the CTO of Rapid Images. He has an extensive background in Computer Graphics with different perspectives through the years, 
      all the way from software engineer to sales (and back). Oliver will (yet again) talk about generative AI and give a few examples of what
      Rapid has done with it so far and intends to do with it in the future.`,
    weblink: [
      {
        linkName: "linkedin",
        link: "https://www.linkedin.com/in/dr-oliver-edsberger-54496b20/",
      }
    ],
  
    image: [Oliver],
  },
];
