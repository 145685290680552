import React from "react";
import styled from "styled-components";
import { InformationCubesData } from "../Data/CubeInfoData";

const CubeContainer = styled.div`
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row;
  }
`;
const Cube = styled.div`
  &:first-child {
  }
  border-top: 1px solid white;

  &:last-child {
    border-bottom: 1px solid white;
  }
  border-right: 1px solid white;
  border-left: 1px solid white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  @media (min-width: 750px) {
    border-bottom: 1px solid white;
    min-width: 15rem;
  }
`;

const CubeTitle = styled.div`
  padding: 10px;
  text-align: left;
  font-weight: 600;
  font-family: "Manrope", sans-serif;
  font-size: 24px;
`;
const CubeDescription = styled.div`
  padding: 10px;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
`;
const CubeImage = styled.div`
  padding: 10px;
`;
export function InformationCubes() {
  return (
    <>
      <CubeContainer>
        {InformationCubesData.map((cube, i) => {
          return (
            <Cube key={i}>
              <CubeImage>{cube.image}</CubeImage>
              <CubeTitle>{cube.title}</CubeTitle>
              <CubeDescription>{cube.description}</CubeDescription>
            </Cube>
          );
        })}
      </CubeContainer>
    </>
  );
}
