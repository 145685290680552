import React from "react";
import CubeVideo from "../Assets/bg_mobile.mp4";
import styled from "styled-components";

const Video = styled.video`
  margin-top: 10rem;
  width: 520px;
  height: 520px;
  @media (min-width: 1200px) {
    margin-top: 0;
    width: 100%;
    height: 100%;
  }
`;
class RapidCubeVideo extends React.Component {
  render() {
    return (
      <Video
        width="100%"
        height="100%"
        preload="auto"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={CubeVideo} type="video/mp4" />
      </Video>
    );
  }
}

export default RapidCubeVideo;
