import React from "react";
import styled from "styled-components";
import EventOne from "../Assets/event.1.png";
import EventTwo from "../Assets/event.2.jpeg";
import EventThree from "../Assets/event.3.jpeg";

const EventContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 1200px) {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    justify-content: center;
  }
`;
const EventPictures = styled.div`
  margin-top: 1.2rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

const EventPicLayout = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  img {
    @media (max-width: 1200px) {
      width: 100%;
    }
  }
  @media (min-width: 1200px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
  }
`;
const EventDescription = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 750px) {
    display: flex;
    justify-content: center;
    min-width: 20rem;
  }
  @media (min-width: 1200px) {
    max-width: 20rem;
  }
`;

const EventText = styled.p`
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  font-family: "Manrope", sans-serif;
`;

const EventsTitle = styled.h1`
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 28px;
  margin: 0;
  text-align: left;
`;

const ImageHolder = styled.img`
  object-fit: cover;
  max-height: 290px;
  width: 345px;
`;

const SeperateImageHolder = styled.img`
  object-fit: cover;
  max-height: 290px;
  width: 700px;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export function PreviousEvents() {
  return (
    <>
      <EventContainer>
        <EventPictures>
          <EventPicLayout>
            <ImageHolder src={EventOne} alt="3D Event" />
            <ImageHolder src={EventTwo} alt="3D Event" />
          </EventPicLayout>
          <SeperateImageHolder src={EventThree} alt="3D Event" />
        </EventPictures>
        <EventDescription>
          <EventsTitle>Previous Events</EventsTitle>
          <EventText>
            During the outgoing pandemic conditions we have resurrected our successful format 
            of CG community events and have since then hosted three of these. Our ambition is
            to host two per year going forward.
          </EventText>
          <EventText>
            Our industry, whether you are an artist or an engineer, is a relatively small ecosystem,
            so we like to invite everyone with an interest in the topic, regardless if you are a student, 
            a customer, a partner or maybe even a competitor. In the end we are all part of an effort to 
            make the world a slightly better place - in our case through better digital experiences. 
          </EventText>
        </EventDescription>
      </EventContainer>
    </>
  );
}
