import React from "react";
import styled from "styled-components";

const AgendaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 1050px) {
    flex-direction: row;
  }
`;
const AgendaTitle = styled.h1`
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  font-size: 35px;
  margin-bottom: 10rem;
  @media (min-width: 1050px) {
    margin-bottom: 0;
    margin-top: 0;
  }
`;
const AgendaDivider = styled.div`
  border-bottom: 1px solid white;
  width: 15rem;
  transform: rotate(90deg);
  margin-bottom: 6.3rem;
  @media (min-width: 1050px) {
    margin-left: 3rem;
    transform: rotate(0deg);
    margin-bottom: 0rem;
  }
`;

const AgendaList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  flex-direction: column;
  border: 1px solid white;
  padding: 2rem;
`;
const AgendaListItem = styled.li`
  font-weight: 600;
  font-size: 24px;
  font-family: "Manrope", sans-serif;
  margin-bottom: 1rem;
  text-align: left;
  &:last-child {
    margin-bottom: 0;
  }
`;

export function Agenda() {
  return (
    <AgendaContainer>
      <AgendaTitle>Agenda</AgendaTitle>
      <AgendaDivider> </AgendaDivider>

      <AgendaList>
        <AgendaListItem>17:00 - Open Doors</AgendaListItem>
        <AgendaListItem>17:15 - Food & Mingle</AgendaListItem>
        <AgendaListItem>
          17:45 - Elin Adermalm & Kajsa Blomberg talk about beauty in the ordinary
        </AgendaListItem>
        <AgendaListItem>
          18:10 - Alexander Young talks about the Omniverse </AgendaListItem>
        <AgendaListItem>
          18:30 - Oliver talks about Generative AI in context of rendering & images
        </AgendaListItem>
        <AgendaListItem>18:50 - Drinks, Snacks & Mingle</AgendaListItem>
      </AgendaList>
    </AgendaContainer>
  );
}
