import React from "react";
export const InformationCubesData = [
  {
    title: "Food for everybody",
    description:
      "No need to plan your dinner - we offer tasty wraps you can grab, as well as an assortment of unhealthy snacks! Make sure to mention any allergies or preferences when signing up!",
    image: [
      <svg
        width="66"
        height="65"
        viewBox="0 0 66 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M51.9582 40.625V8.125C38.9148 20.5671 38.3542 30.6556 38.4165 40.625H51.9582ZM51.9582 40.625V56.875H49.2498V48.75M22.1665 10.8333V56.875M14.0415 10.8333V18.9583C14.0415 21.1132 14.8975 23.1798 16.4213 24.7036C17.945 26.2273 20.0116 27.0833 22.1665 27.0833C24.3214 27.0833 26.388 26.2273 27.9117 24.7036C29.4355 23.1798 30.2915 21.1132 30.2915 18.9583V10.8333"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>,
    ],
  },
  {
    title: "Drinks too",
    description:
      "Of course we also have some liquid things to enjoy as well - with and without alcohol. And in different colors!",
    image: [
      <svg
        width="66"
        height="65"
        viewBox="0 0 66 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.875 56.875H41.125C41.8433 56.875 42.5322 56.5897 43.0401 56.0817C43.548 55.5738 43.8333 54.885 43.8333 54.1667V44.349C43.8333 40.5654 44.6187 36.8333 46.1219 33.4479L46.9615 31.5521C48.4673 28.1667 49.25 25.4502 49.25 21.6667V10.8333C49.25 10.115 48.9647 9.42616 48.4567 8.91825C47.9488 8.41034 47.26 8.125 46.5417 8.125H19.4583C18.74 8.125 18.0512 8.41034 17.5433 8.91825C17.0353 9.42616 16.75 10.115 16.75 10.8333V21.6667C16.75 25.4502 17.5354 28.1667 19.0385 31.5521L19.8781 33.4479C21.3933 36.8821 22.1728 40.5954 22.1667 44.349V54.1667C22.1667 54.885 22.452 55.5738 22.9599 56.0817C23.4678 56.5897 24.1567 56.875 24.875 56.875Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.75 21.6667H49.25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>,
    ],
  },
  {
    title: "Networking",
    description:
      "Maybe the most important part of the evening? Meet old friends and make new ones! Whether you are a techie, an artist or both (or neither) you'll find someone to chat with!",
    image: [
      <svg
        width="66"
        height="65"
        viewBox="0 0 66 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M57.3752 37.9167L49.2502 29.7917H30.2918C29.5735 29.7917 28.8847 29.5063 28.3767 28.9984C27.8688 28.4905 27.5835 27.8016 27.5835 27.0833V10.8333C27.5835 10.115 27.8688 9.42616 28.3767 8.91825C28.8847 8.41034 29.5735 8.125 30.2918 8.125H54.6668C55.3851 8.125 56.074 8.41034 56.5819 8.91825C57.0898 9.42616 57.3752 10.115 57.3752 10.8333V37.9167Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.4167 40.6249V46.0416C38.4167 46.7599 38.1313 47.4488 37.6234 47.9567C37.1155 48.4646 36.4266 48.7499 35.7083 48.7499H16.75L8.625 56.8749V29.7916C8.625 29.0733 8.91034 28.3844 9.41825 27.8765C9.92616 27.3686 10.615 27.0833 11.3333 27.0833H16.75"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>,
    ],
  },
  {
    title: "Goodiebag",
    description:
      "We like to contribute to a better world with less waste of resources. So we hope you appreciate the abscence of a goodiebag. You can bring home lots of fond memories though!",
    image: [
      <svg
        width="66"
        height="65"
        viewBox="0 0 66 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.6463 21.6667H48.3561C49.1371 21.6667 49.9089 21.8354 50.6185 22.1616C51.3282 22.4877 51.9589 22.9634 52.4675 23.5561C52.9761 24.1488 53.3505 24.8445 53.565 25.5954C53.7796 26.3464 53.8292 27.1348 53.7105 27.9067L50.3115 49.9851C50.0165 51.9038 49.0442 53.6534 47.5707 54.9173C46.0972 56.1811 44.2199 56.8756 42.2786 56.8751H23.7211C21.7803 56.875 19.9037 56.1802 18.4307 54.9164C16.9578 53.6526 15.9859 51.9033 15.6909 49.9851L12.2919 27.9067C12.1732 27.1348 12.2228 26.3464 12.4374 25.5954C12.6519 24.8445 13.0263 24.1488 13.5349 23.5561C14.0435 22.9634 14.6742 22.4877 15.3839 22.1616C16.0935 21.8354 16.8653 21.6667 17.6463 21.6667Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.875 29.7917V16.25C24.875 14.0951 25.731 12.0285 27.2548 10.5048C28.7785 8.98102 30.8451 8.125 33 8.125C35.1549 8.125 37.2215 8.98102 38.7452 10.5048C40.269 12.0285 41.125 14.0951 41.125 16.25V29.7917"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>,
    ],
  },
];
